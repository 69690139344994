@import 'antd/dist/antd.css';

a {
  text-decoration: none;
  color: black;
}

img, svg {
  position: relative;
  width: 100%;
  height: 100%;
}

ul, li {
  padding: 0;
  margin: 0;
  list-style: none;
}

p {
  margin: 0;
  padding: 0;
}

div, p, a, span, button {
  letter-spacing: -0.4px;
}